import React, { useState, useContext, useEffect } from "react";

import { findUsers, deleteUser, updateUserStatusAdmin } from "../api";

import { UserContext } from "../context";
import { Box, Heading, Flex, Spinner, PseudoBox, Checkbox, Stack, Select, Text, Button } from "@chakra-ui/core";

import userRoles from "../common/userRoles";
import { UserStatusBadge, ticketStatuses } from "../common/TicketStatus";


import UserBox from "../common/UserBox";
import { CardWrapper } from "../common/Card";
import { SavingButton } from "../common/SavingStatusBox";

function UserDetailsCard({ selectedUsers, allUsers }) {
    const { user } = useContext(UserContext);
    const singleUser = allUsers.find((u) => u.id === selectedUsers[0]);
    const handleDelete = async () => {
        if (window.confirm(`Are you sure you want to delete ${singleUser.name}?`)) {
            const res = await deleteUser(singleUser.id, { userId: user.id, key: user.key });
            // reload page
            if (res === "ok") {
                window.location.reload();
            }
        }
    }

    return (
        <CardWrapper>
            {selectedUsers.length === 1 ? (
                <>
                    <Text>Single user details</Text>


                    <UserBox user={singleUser} showBadge={false} hideIcon={true} />
                    <Button variantColor="red" onClick={handleDelete}>
                        Delete
                    </Button>



                </>


            ) : (
                <Text>Select a single user </Text>
            )}
        </CardWrapper>
    );
}

export function AdminPanel({ navigate }) {
    const { user } = useContext(UserContext);
    const [allUsers, setAllUsers] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [newStatus, setNewStatus] = useState(ticketStatuses.WANTING);
    const loading = !allUsers;

    function onSelectChange(id) {
        if (selectedUsers.includes(id)) {
            setSelectedUsers(selectedUsers.filter((item) => item !== id));
        } else {
            setSelectedUsers([...selectedUsers, id]);
        }
    }
    useEffect(() => {
        async function fetchData() {
            const newAllUsers = await findUsers(user.id, user.key);
            // sort by user.name
            newAllUsers.sort((a, b) => a.name.localeCompare(b.name));
            setAllUsers(newAllUsers);
        }
        if (loading) {
            fetchData();
        }
    }, [
        loading,
        user.id,
        user.key,
    ]);

    async function saveStatus() {
        var success = true;
        const newAllUsers = [...allUsers];
        for (const id of selectedUsers) {
            // find user in all users list
            const selectedUser = newAllUsers.find((u) => u.id === id);
            if (
                (await updateUserStatusAdmin(id, { userId: user.id, key: user.key },
                    selectedUser.status, newStatus)) === "ok"
            ) {
                selectedUser.status = newStatus;
            }
            else {
                success = false;
            }
        }
        setAllUsers(newAllUsers);
        return success;
    }



    return (
        user.role === userRoles.ADMIN &&
        <Flex
            minH="60vh"
            justify="center"
            align="center"
            direction="column"
            textAlign="center"
            wrap="wrap"
        >
            <Heading>Admin Panel</Heading>
            {loading ? (<Flex py={10} justify="center" align="center">
                <Spinner mx="auto" size="xl" />
            </Flex>) : (
                <Flex justify="center" wrap="wrap">
                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>
                            <CardWrapper>
                                {allUsers.map((user) => (
                                    <User key={user.id} user={user} selected={selectedUsers.includes(user.id)} onChange={onSelectChange} />
                                ))
                                }
                            </CardWrapper>
                        </Stack>

                    </Box>
                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>
                            <CardWrapper>
                                {allUsers.filter((user) => selectedUsers.includes(user.id)).map((user) => (
                                    <User key={user.id} user={user} selected={selectedUsers.includes(user.id)} onChange={onSelectChange} />
                                ))}
                                <Select
                                    my="2"
                                    onChange={(e) => setNewStatus(e.target.value)}
                                    value={newStatus}
                                >
                                    <option value={ticketStatuses.WANTING}>WANTING</option>
                                    <option value={ticketStatuses.PURCHASED}>PURCHASED</option>
                                    <option value={ticketStatuses.PURCHASED}>CONFIRMED</option>
                                    <option value={ticketStatuses.NOT_WANTING}>NOT_WANTING</option>
                                    <option value={ticketStatuses.HAS_TICKET}>HAS_TICKET</option>
                                </Select>
                                <SavingButton saveCb={saveStatus}
                                    isEditing={true}
                                    setIsEditing={undefined}
                                />
                            </CardWrapper>
                            <UserDetailsCard selectedUsers={selectedUsers} allUsers={allUsers} />
                        </Stack>
                    </Box>
                    <Box m="4" p="4" maxW="xl">
                        <Stack spacing={8}>

                        </Stack>
                    </Box>

                </Flex>

            )}
        </Flex>
    );
}

function User({ user, selected, onChange }) {
    return (
        <PseudoBox
            as="div"
            display="flex"
            alignItems="center"
            height={8}
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="grey.500"
            bg="white"
            px="2"
            role="group"
        >
            <Checkbox value={user.id} isChecked={selected} onChange={(e) => {

                onChange(user.id);
            }} >
                <UserBox user={user} showBadge={false} hideIcon={true} />
            </Checkbox>
            <Box alignSelf="flex-end" ml="auto">

                <UserStatusBadge status={user.status} isOutlined />
            </Box>

        </PseudoBox>
    );
}