import React, { useState, useContext, useEffect, useCallback } from "react";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import customTheme from "./theme";
import "./App.css";
import { Router } from "@reach/router";

import { UserContext, UserProvider } from "./context";

import { getUser } from "./api";

import Header from "./common/Header";
import Banner from "./common/Banner";
import Footer from "./common/Footer";
import SignUp from "./pages/SignUp";
import LoginPage from "./pages/LoginPage";
import Dashboard from "./dashboard/Dashboard";
import BookingPages from "./booking/BookingPages";
import SharedDetailsPage from "./shared-details/SharedDetailsPage";
import HomePage from "./home/HomePage";

import { ticketStatuses } from "./common/TicketStatus";
import { AdminPanel } from "./pages/AdminPanel";

let initialUser = {
  id: "769291eb-0779-46c6-b3ea-8ab1d3e5ee8a",
  key: "db4163fd7924da26f65f8700cbed1397",
  name: "Mark Uckermann",
  regId: "5050",
  postcode: "LS35 DN3",
  created_at: 1588011160311,
  status: 1,
  rankings: ["769291eb-0779-46c6-b3ea-8ab1d3e5ee8a"],
  buyer: {},
  boughtFor: [],
};

initialUser = null;

const PrivateRoute = (props) => {
  const { user } = useContext(UserContext);

  let { as: Comp, ...restOfTheProps } = props;
  return user ? <Comp {...restOfTheProps} /> : <LoginPage />;
};

function App() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || initialUser
  );

  const updateUser = useCallback(
    (updatedUser) => {
      if (updatedUser) {
        let newUser = updatedUser;
        if (user) {
          newUser = { ...user, ...updatedUser }; //merge together
        }

        if (newUser.shareId === "deactivated") {
          newUser.shareId = null;
        }
        if (!newUser.buyer) {
          newUser.buyer = {};
        }
        if (!newUser.boughtFor) {
          newUser.boughtFor = [];
        }
        newUser.hasBoughtForLocked = false; // Flag set if the user has a lock on anybody
        for (const u of newUser.boughtFor) {
          if (u.status === ticketStatuses.LOCKED) {
            newUser.hasBoughtForLocked = true;
            break;
          }
        }
        setUser(newUser);
      } else {
        //logOut
        setUser(null);
      }
    },
    [user]
  );

  const [lastFetch, setLastFetch] = useState(0);
  const [isFetching, setIsFetching] = useState(0);

  useEffect(() => {
    async function fetchUser() {
      const newUser = await getUser(user.id, user.key);
      if (newUser) {
        setLastFetch(Date.now());
        updateUser(newUser);
        setIsFetching(false);
        console.log("Fetched User");
      }
    }

    if (user && !isFetching && Date.now() - lastFetch > 5000) {
      setIsFetching(true);
      setTimeout(() => fetchUser(), 1500);
    }

    localStorage.setItem("user", JSON.stringify(user));
  }, [user, lastFetch, isFetching, updateUser]);

  return (
    <ThemeProvider theme={customTheme}>
      <UserProvider value={{ user, updateUser }}>
        <CSSReset />
        <Banner />
        <Header />
        <main>
          <Router>
            <NotFound default />
            <HomePage path="/" />
            <SignUp path="/join" preFill="none" />
            <SignUp path="/join/:preFill" />
            <SharedDetailsPage path="/details/:shareId" />
            <PrivateRoute as={Dashboard} path="/dashboard" />
            <PrivateRoute as={BookingPages} path="/book" />
            <PrivateRoute as={AdminPanel} path="/admin" />

          </Router>
        </main>
        <Footer />
      </UserProvider>
    </ThemeProvider>
  );
}

const NotFound = () => <p>Sorry, nothing here</p>;

export default App;
