import React, { useState, useContext } from "react";
import { UserContext } from "../context";

import RankingTable from "./RankingTable";

import { findUsers } from "../api";

import { EditableCard } from "../common/Card";

function Rankings() {
  const { user } = useContext(UserContext);
  const [isEditing, setIsEditing] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  async function load() {
    const newAllUsers = await findUsers(user.id, user.key);
    setAllUsers(newAllUsers);
  }

  function toggleEdit() {
    if (!isEditing) {
      load();
    }
    setIsEditing(!isEditing);
  }

  return (
    <EditableCard
      heading="Priority List"
      onEditClick={toggleEdit}
      label="Edit your priority list"
    >
      <RankingTable isEditing={isEditing} allUsers={allUsers} />
    </EditableCard>
  );
}

export default Rankings;
